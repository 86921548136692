import React from 'react'

// Third Party
import styled, { css } from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

interface ShadowImageProps {
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Image
}

const StyledShadowImage = styled.section``

const ImageWrapper = styled.div``

const StyledPlaatjie = styled(Plaatjie)`
  border-radius: 15px;
  max-width: 900px;
  z-index: 2;

  & img {
    border-radius: 15px;
  }
`

const ImageShadow = styled.div<{ position: string }>`
  position: absolute;
  width: 900px;
  height: 100%;
  z-index: 1;
  border-radius: 15px;

  ${({ position }) =>
    position === 'start' &&
    css`
      left: 540px;
      top: 110px;

      @media (max-width: 1199px) {
        left: 340px;
      }

      @media (max-width: 991px) {
        left: 55px;
        top: 55px;
      }

      @media (max-width: 575px) {
        left: 30px;
        top: 30px;
      }
    `}

  ${({ position }) =>
    position === 'center' &&
    css`
      left: 540px;
      top: 110px;

      @media (max-width: 1199px) {
        left: 340px;
      }

      @media (max-width: 991px) {
        left: 55px;
        top: 55px;
      }

      @media (max-width: 575px) {
        left: 30px;
        top: 30px;
      }
    `}

    ${({ position }) =>
    position === 'end' &&
    css`
      right: 540px;
      top: 110px;

      @media (max-width: 1199px) {
        right: 340px;
      }

      @media (max-width: 991px) {
        right: 55px;
        top: 55px;
      }

      @media (max-width: 575px) {
        right: 30px;
        top: 30px;
      }
    `}
`

const ShadowImage: React.FC<ShadowImageProps> = ({ fields }) => (
  <StyledShadowImage>
    <div className="container">
      <ImageWrapper
        className={`d-flex justify-content-${fields.position} position-relative`}
      >
        <StyledPlaatjie image={fields.image} alt="" />
        <ImageShadow
          position={fields.position || 'start'}
          style={{ backgroundColor: fields.shadowcolor }}
        />
      </ImageWrapper>
    </div>
  </StyledShadowImage>
)

export default ShadowImage
